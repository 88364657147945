import { FormEventHandler, PropsWithChildren, forwardRef } from 'react'
import { TestProps } from 'typ'

export interface FormProps extends TestProps, PropsWithChildren {
  onSubmit?: FormEventHandler<HTMLFormElement> | undefined
  className?: string
}
export const Form = forwardRef<HTMLFormElement, FormProps>(
  ({ onSubmit, children, ...props }, ref) => {
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      onSubmit?.(e)
    }
    return (
      <form {...props} onSubmit={handleSubmit} ref={ref}>
        {children}
      </form>
    )
  }
)

Form.displayName = 'Form'
