import { FC } from 'react'
import { BudouXText } from 'ui-v2/components/Text'
import { disclaimerCx } from './Disclaimer.css'

export interface DisclaimerProps {
  children: string
}
export const Disclaimer: FC<DisclaimerProps> = ({ children }) => (
  <div className={disclaimerCx}>
    <BudouXText color="colorsTextNeutralDefaultLowContrast" text={children} variant="paragraphMd" />
  </div>
)
