import { yupResolver } from '@hookform/resolvers/yup'
import { BaseSyntheticEvent, useEffect, useState } from 'react'
import { UseFormProps as CorseUseFormProps, useForm as coreUseForm } from 'react-hook-form'
import { Locale } from 'typ'
import { useLocale } from '../../hooks/useLocale'

export type TypedSchema = {
  __inputType: any
  __outputType: any
}

export type TypesFromSchema<TSchema extends TypedSchema> = TSchema['__inputType']
export type ReturnTypesFromSchema<TSchema extends TypedSchema> = TSchema['__outputType']

type UseFormProps<T extends TypedSchema> = Omit<CorseUseFormProps, 'resolver'> & {
  validationSchema?: T
}
export type UseFormReturn<T extends TypedSchema> = Omit<
  ReturnType<typeof coreUseForm>,
  'handleSubmit'
> & {
  submitForm: (
    onValid: (v: T) => void
  ) => (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}

export function useForm<T extends TypedSchema>(
  props: UseFormProps<T>
): UseFormReturn<ReturnTypesFromSchema<T>> {
  const { locale } = useLocale()
  const mode = props.mode ?? 'onSubmit'
  const [prevLocale, setPrevLocale] = useState<Locale | null>(null)
  const { handleSubmit, ...methods } = coreUseForm({
    ...props,
    mode,
    reValidateMode: props.mode === 'all' ? undefined : (props.reValidateMode ?? 'onChange'),
    resolver: props.validationSchema
      ? (yupResolver(props.validationSchema as any) as any)
      : undefined,
  })

  // This needs to stay here otherwise the form will not trigger validation on locale change
  const { isSubmitted: _ } = methods.formState

  useEffect(() => {
    if (prevLocale === null && locale !== null) {
      setPrevLocale(locale)
    } else if (
      prevLocale !== null &&
      locale !== prevLocale &&
      ((mode === 'onSubmit' && methods.formState.isSubmitted) || mode !== 'onSubmit')
    ) {
      const fields = Object.keys(methods.getValues())
      const touchedFields = fields.filter((field) => methods.getFieldState(field).isTouched)
      methods.trigger(mode === 'onSubmit' ? undefined : touchedFields)
      setPrevLocale(locale)
    }
  })

  return {
    ...methods,
    submitForm: handleSubmit,
  }
}
