/**
 *
 * @param input a japanese, romaji or mixed string
 * @returns an array of substrings, each of which is either A) a full romaji word or collection of characters B) a japanese text string C) whitespace
 */
export const splitJapaneseAndRomaji = (input: string) =>
  input
    .trim()
    .split(/([ぁ-んァ-ン\u3000-\u303F\uFF00-\uFFEF\u4E00-\u9FAF]+|[A-Za-z]+)/u)
    .filter((segment) => segment !== '')
