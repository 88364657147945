
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { setGoogleAnalyticsUser } from '@/auth'
import { LoginView } from '@/features/auth/LoginView'
import { AuthLayout } from '@/layouts/v2/AuthLayout'
import { NextPageWithLayout } from '@/types'
import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'
import { useEffect } from 'react'

 const _getStaticProps = async () => ({ props: {} })

export const d = {
  pageTitle: 'page-titles:login',
}

const Login: NextPageWithLayout = () => {
  const { t } = useTranslation()

  useEffect(() => {
    setGoogleAnalyticsUser(null)
  }, [])

  return (
    <>
      <Head>
        <title>{t(d.pageTitle)}</title>
      </Head>
      <LoginView />
    </>
  )
}

Login.getLayout = function getLayout(page) {
  return <AuthLayout>{page}</AuthLayout>
}

export default Login


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  