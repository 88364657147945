import { FieldPath, FieldValues, useController } from 'react-hook-form'
import {
  AlphanumericProps,
  NumberProps,
  PatternProps,
  TextInputBoxProps,
  TextProps,
} from '../input-boxes/types'
import { TextField, TextFieldOwnProps } from '../input-fields'
import { ControlledFieldCoreProps, ControlledFieldProps } from './types'

type Omitted = 'inputRef' | 'isError' | 'helpText' | 'onChange' | 'value' | 'onBlur' | 'name'
type FilteredTextFieldProps =
  | Omit<AlphanumericProps, Omitted>
  | Omit<NumberProps, Omitted>
  | Omit<PatternProps, Omitted>
  | Omit<TextProps, Omitted>

type ControlledTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = ControlledFieldProps<TFieldValues, TName> &
  TextFieldOwnProps &
  ControlledFieldCoreProps &
  FilteredTextFieldProps &
  Partial<Pick<TextInputBoxProps, 'onChange'>>

export const ControlledTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  rules,
  shouldUnregister,
  defaultValue,
  isDisabled,
  hideHelpText,
  onChange,
  ...textFieldProps
}: ControlledTextFieldProps<TFieldValues, TName>) => {
  const {
    field: { disabled, ref, onChange: innerOnChange, ...rest },
    fieldState,
  } = useController({ name, control, rules, defaultValue, shouldUnregister })

  return (
    <TextField
      {...textFieldProps}
      {...rest}
      onChange={(e) => {
        // Removing this because it creates issues with Yup.required() as
        // empty string is not considered as empty value
        // if (isNil(e.target.value)) {
        //   e.target.value = ''
        // }
        innerOnChange(e)
        onChange?.(e)
      }}
      isDisabled={disabled || isDisabled}
      inputRef={textFieldProps.inputRef ?? ref}
      isError={Boolean(fieldState.error)}
      helpText={hideHelpText ? undefined : (fieldState.error?.message ?? '')}
    />
  )
}
