import { LanguageSwitch } from 'common-v2/components/LanguageSwitch'
import { FC, PropsWithChildren } from 'react'
import { Box } from 'ui-v2/components/Box'
import { Flex } from 'ui-v2/components/Flex'
import { LogoShortOnWhite } from 'ui-v2/components/Logo'
import { languageSwitchContainerCx } from './AuthLayout.css'

export interface AuthLayoutProps extends PropsWithChildren {}
export const AuthLayout: FC<AuthLayoutProps> = ({ children }) => (
  <Box py="1400" px="900" position="relative">
    <div className={languageSwitchContainerCx}>
      <LanguageSwitch />
    </div>
    <Flex justify="flex-start" align="center" gap="1300" direction="column">
      <LogoShortOnWhite height={41} width={184} />
      {children}
    </Flex>
  </Box>
)
