import { forwardRef, useState } from 'react'
import { mergeProps, useFocus, useObjectRef } from 'react-aria'
import { TextInputBox } from '../input-boxes'
import { TextInputBoxProps } from '../input-boxes/types'
import { FieldWrapper } from './common/FieldWrapper'
import { useFieldWrapperProps } from './common/useFieldWrapperProps'
import { FieldWrapperProps } from './types'

export type TextFieldOwnProps = Omit<FieldWrapperProps, 'id' | 'inputElement'> & {
  id?: string
  inputRef?: React.Ref<HTMLInputElement>
}
export type TextFieldProps = TextInputBoxProps & TextFieldOwnProps

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ inputRef, ...props }, forwardedRef) => {
    const ref = useObjectRef(forwardedRef)
    const { fieldWrapperProps, testProps, ...rest } = useFieldWrapperProps(props)
    const [isFocused, onFocusChange] = useState(false)
    const { focusProps } = useFocus({
      onFocusChange,
      isDisabled: props.isDisabled,
    })
    const mergedInputProps = mergeProps(rest, focusProps)
    const mergedWrapperProps = mergeProps(fieldWrapperProps, testProps)
    return (
      <FieldWrapper {...mergedWrapperProps} hasFocus={isFocused} ref={ref}>
        <TextInputBox
          {...(mergedInputProps as TextInputBoxProps)}
          id={fieldWrapperProps.id}
          aria-errormessage={fieldWrapperProps.errorId}
          aria-invalid={fieldWrapperProps.isError}
          ref={inputRef}
          isDisabled={props.isDisabled}
          autoFocus={props.autoFocus}
        />
      </FieldWrapper>
    )
  }
)

TextField.displayName = 'TextField'
