import { LangCookies } from 'contexts'
import { maxExpiry } from 'datetime'
import setLanguage from 'next-translate/setLanguage'
import useTranslation from 'next-translate/useTranslation'
import { FC, useMemo } from 'react'
import { useCookies } from 'react-cookie'
import { StyledProps } from 'typ'
import { Button } from 'ui-v2/components/Button'
import { Flex } from 'ui-v2/components/Flex'
import { Text } from 'ui-v2/components/Text'
import { TooltipTrigger } from 'ui-v2/components/Tooltip'
import { iconSprinkles } from 'vanilla-extract-config/sprinkles'

const locales = ['ja', 'en']
const d = {
  tooltipText: 'common:change-language',
}
export const LanguageSwitch: FC<React.PropsWithChildren<StyledProps>> = () => {
  const { t, lang } = useTranslation()
  const [_cookies, setCookie] = useCookies([LangCookies.NEXT_LOCALE])
  const targetLang = useMemo(() => locales.filter((lng) => lng !== lang).join(), [lang])

  return (
    <TooltipTrigger tooltipContent={t(d.tooltipText)}>
      <Button
        variant="text"
        color="neutral"
        size="lg"
        onPress={() => {
          setCookie(LangCookies.NEXT_LOCALE, targetLang, {
            path: '/',
            expires: maxExpiry,
          })
          setLanguage(targetLang)
        }}
        data-testid="language-switch-button"
        data-cy="language-switch-button"
      >
        <Flex gap="400" align="center">
          {/* Design is using a custom size for icons here */}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.25 5.25H15.75M9 3V5.25M12.75 21L17.25 10.5L21.75 21M14.1328 18H20.3672M13.1859 5.25C13.1859 5.25 12.0469 9.65625 9.32812 12.9844C6.60938 16.3125 3.75 18 3.75 18"
              className={iconSprinkles({ stroke: 'inherit' })}
              strokeWidth="1.125"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 15.75C12 15.75 10.3594 14.4844 8.625 12.2344C6.89062 9.98438 6 8.25 6 8.25"
              className={iconSprinkles({ stroke: 'inherit' })}
              strokeWidth="1.125"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Text variant="labelMd">{lang === 'ja' ? 'English' : '日本語'}</Text>
        </Flex>
      </Button>
    </TooltipTrigger>
  )
}
