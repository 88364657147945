import { Maybe } from 'typ'
import { isNil } from 'typeguards'

type GoogleAnalyticsUser = {
  id: string
  workspaceId: string
  workspaceName: Maybe<string>
  userName: Maybe<string>
} | null

export const setGoogleAnalyticsUser = (user: GoogleAnalyticsUser) => {
  if (typeof window === 'undefined' || typeof window.gtag !== 'function') {
    return
  }
  const userProperties = isNil(user)
    ? {
        tensor_user_id: 'not_available',
        workspace_id: 'not_available',
        workspace_name: 'not_available',
        tensor_user_name: 'not_available',
      }
    : {
        tensor_user_id: user.id,
        tensor_user_name: user.userName,
        workspace_id: user.workspaceId,
        workspace_name: user.workspaceName,
      }

  window.gtag('set', 'user_properties', userProperties)
}
