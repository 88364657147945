import { FC, PropsWithChildren } from 'react'
import { Text } from 'ui-v2/components/Text'

export interface TitleProps extends PropsWithChildren {}
export const Title: FC<TitleProps> = ({ children }) => (
  <Text
    textAlign="center"
    alignSelf="center"
    color="colorsTextNeutralDefaultHighContrast"
    variant="headingXl"
  >
    {children}
  </Text>
)
