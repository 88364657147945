import { loadDefaultJapaneseParser } from 'budoux'
import { Fragment, forwardRef, useMemo } from 'react'
import { containsJapaneseCharacter, splitJapaneseAndRomaji } from 'string'
import { areSameType } from 'typeguards'
import { wrapperCx } from './BudouXText.css'
import { Text, TextProps } from './Text'

export const buildBudouXText = (text: string | undefined) => {
  if (!text || typeof text !== 'string') return ''
  if (!containsJapaneseCharacter(text)) return text
  const parseJp = (t: string) => loadDefaultJapaneseParser().parse(t)
  const splitString = splitJapaneseAndRomaji(text)

  const result: (string | JSX.Element | JSX.Element[])[] = []
  splitString.forEach((s, i) => {
    if (containsJapaneseCharacter(s)) {
      const parsed = parseJp(s)
      result.push(
        parsed.map((p, j) => (
          <Fragment key={`s-${i}-p-${j}`}>
            <wbr />
            {p}
            <wbr />
          </Fragment>
        ))
      )
    } else if (typeof s === 'string' && areSameType(result[result.length - 1], s)) {
      result[result.length - 1] += s
    } else {
      result.push(s)
    }
  })

  const lastElement = result[result.length - 1]
  if (typeof lastElement === 'string') {
    result[result.length - 1] = lastElement.trimEnd()
  }
  return result
}

export type BudouXTextProps = Omit<TextProps, 'children' | 'ellipsize'> & {
  children?: never
  text: string | undefined
}

export const BudouXText = forwardRef<HTMLDivElement, BudouXTextProps>(
  ({ as = 'div', text, ...props }, ref) => {
    const parsedText = useMemo(() => buildBudouXText(text), [text])
    return (
      <Text {...props} as={as} ref={ref} className={wrapperCx}>
        {parsedText}
      </Text>
    )
  }
)

BudouXText.displayName = 'BudouXText'
